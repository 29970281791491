import React, { useState } from 'react';
import styles from './RunDetailsComponent.module.css';

const RunDetailsComponent = () => {
    const [activeTab, setActiveTab] = useState('Info');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className={styles.runDetailsContainer}>
            <div className={styles.tabs}>
                <button className={`${styles.tabButton} ${activeTab === 'Info' ? styles.activeTab : ''}`} onClick={() => handleTabClick('Info')}>Info</button>
                <button className={`${styles.tabButton} ${activeTab === 'Note' ? styles.activeTab : ''}`} onClick={() => handleTabClick('Note')}>Note</button>
                <button className={`${styles.tabButton} ${activeTab === 'Attachment' ? styles.activeTab : ''}`} onClick={() => handleTabClick('Attachment')}>Attachment</button>
            </div>
            <div className={styles.runDetailsContent}>
                {activeTab === 'Info' && (
                    <div className={styles.infoContent}>
                        <table className={styles.detailsTable}>
                            <thead>
                                <tr>
                                    <th>Automation Task Name</th>
                                    <th>Automation Scenario</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>NetSuite File Post</td>
                                    <td>JE File Posting in NetSuite</td>
                                    <td>08-12-2024 09:30 PM</td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className={styles.resultHeader}>Automation Result</td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className={styles.resultContent}>File: GLExportNS_Sub24.txt, Success: 1, Failed: 0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                {/* Note and Attachment contents would go here */}
            </div>
        </div>
    );
};

export default RunDetailsComponent;


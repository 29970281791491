import React, { useState, useEffect } from 'react';
import {  CssBaseline, AppBar, Toolbar, Box, Typography, ListItemIcon, Avatar, IconButton, MenuItem, Menu, Badge, Grid, Card, CardContent } from '@mui/material';
import IntegrationsHeader from '../../components/IntegrationsHeader/DetailViewHeader';
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import logo from '../../../../assetts/images/RUNMYBOT_logo2.svg'
import IntegrationsSidebar from '../../components/DetailViewSidebar/DetailViewSidebar';
import AutomationDetailsPage from '../../components/AutomationDetails/AutomationDetails';
import styles from './DetailViewPage.module.css';


const DetailViewPage = () => {
    const [filters, setFilters] = useState([]);
    const [tiles, setTiles] = useState([]);

    useEffect(() => {
        import('../../data/filterOptions.json').then((data) => setFilters(data.default));
        import('../../data/workflowTiles.json').then((data) => setTiles(data.default));
    }, []);

    return (
        <Box
      sx={{
        width: '100%',
        maxWidth: '1512px', // Set max width to 1512px
        margin: '0 auto', // Center the container horizontally
        overflowX: 'hidden', // Prevent horizontal scrolling
      }}
    >
      <CssBaseline />
      <AppBar  
        position="fixed" 
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1, 
          backgroundColor: '#F5F9FF', 
          height: 80, // Set the desired height here
          boxShadow: 'none', // Remove the shadow
          borderBottom: '2px solid #E8E8E8' // Add the bottom border
        }}
      >
        <Toolbar  className="toolbar">
          <img src={logo} alt="Logo" style={{ height: '60%', marginRight: 16 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <IconButton sx={{ mr: 2, color: '#B3B3B3' }}>
              <Badge badgeContent={0} color="primary">
                <CircleNotificationsOutlinedIcon />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
        {/* Notification Icon */}
        
      </AppBar>
      <div className={styles.DetailViewPage}>
            <div className={styles.headerAndContent}>
                <IntegrationsHeader />
                <div className={styles.mainContent}>
                    <IntegrationsSidebar filters={filters} />
                    <div className={styles.contentContainer}>
                       <AutomationDetailsPage />
                    </div>
                </div>
            </div>
        </div>
    </Box>
 
    );
};

export default DetailViewPage;

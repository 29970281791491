import React, { useState } from 'react';
import styles from './IntegrationsSidebar.module.css';
import { ReactComponent as SystemSettingsIcon } from '../../../../assetts/images/systemSettings.svg'; // Adjust the path based on your project structure
import { ReactComponent as DropdownIconClosed } from '../../../../assetts/images/dropdownIconClosed.svg'; // Closed dropdown arrow icon
import { ReactComponent as DropdownIconOpen } from '../../../../assetts/images/dropdownIconOpen.svg'; // Open dropdown arrow icon
import { ReactComponent as SearchIcon } from '../../../../assetts/images/searchIcon.svg'; // Search icon

const IntegrationsSidebar = ({ filters }) => {
    const [open, setOpen] = useState(true);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <div className={styles.IntegrationsSidebar}>
            <div className={styles.searchContainer}>
                <SearchIcon className={styles.searchIcon} />
                <input
                    type="text"
                    placeholder="Search..."
                    className={styles.searchInput}
                />
                <hr className={styles.dottedLine} />
            </div>

            <div className={styles.menu}>
                <div className={styles.listHeader} onClick={handleToggle}>
                    <div className={styles.listHeaderContent}>
                        <SystemSettingsIcon className={styles.icon} />
                        <span className={styles.sectionTitle}>By System</span>
                    </div>
                    {open ? (
                        <DropdownIconOpen className={styles.expandIcon} />
                    ) : (
                        <DropdownIconClosed className={styles.expandIcon} />
                    )}
                </div>

                {open && (
                    <ul className={styles.filterList}>
                        {filters.map((filter) => (
                            <li key={filter.id} className={styles.listItem}>
                                <input
                                    type="checkbox"
                                    checked={filter.checked}
                                    className={styles.checkbox}
                                    onChange={() => {}} // Handle change as needed
                                />
                                <span className={styles.listItemText}>{filter.name}</span>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default IntegrationsSidebar;

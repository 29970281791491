import React from 'react';
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import './MyWorkflows.css';
import theme from '../theme';
const MyWorkflows = () => {
    const rows = [
        { name: 'Workflow 1', status: 'Active', lastRun: '2024-08-20', totalRuns: 5, actions: '...' },
        { name: 'Workflow 2', status: 'Inactive', lastRun: '2024-08-18', totalRuns: 10, actions: '...' },
        // Add more rows as needed
      ];
      const statuses = ['Pending', 'In Progress', 'Completed', 'Failed'];
    function getStatusColor(status, theme) {
      switch (status) {
        case 'Pending':
          return theme.palette.warning.main;
        case 'In Progress':
          return theme.palette.info.main;
        case 'Completed':
          return theme.palette.success.main;
        case 'Failed':
          return theme.palette.error.main;
        default:
          return theme.palette.grey[500];
      }
    }
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container alignItems="center" spacing={2}>
        {/* Buttons */}
        <Grid item>
          <Box className="buttonGroup">
            <Button variant="contained" sx={{
              width: '119px',
              height: '32px',
              borderRadius: '24px',
              backgroundColor: '#4F63BE',
              '& .MuiButton-startIcon': {
                borderRadius: '50%',
                padding: '0px',
              },
            }} startIcon={<AddCircleOutlinedIcon sx={{  borderRadius: '50%' }}/>}>Integrations</Button>
            <Button variant="contained" sx={{
              width: '119px',
              height: '32px',
              borderRadius: '24px',
              backgroundColor: '#7398C7',
              '&:hover': {
                backgroundColor: '#7398C7', // Set hover color here
              },
              '& .MuiButton-startIcon': {
                borderRadius: '50%',
                padding: '0px',
              },
            }}   startIcon={<AddCircleOutlinedIcon sx={{  borderRadius: '50%', fontSize: 20  }}/>}>Workflows</Button>
          </Box>
        </Grid>
        
        {/* Right Group: Status Dots */}
        <Grid item>
        <Box display="flex" justifyContent="flex-end">
          {statuses.map((status, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: getStatusColor(status, theme),
                  marginRight: 1,
                }}
              />
              <Typography variant="caption">{status}</Typography>
            </Box>
          ))}
        </Box>
        </Grid>
      </Grid>

      {/* Table or ListView goes here */}
      <Box className="myWorkflowsTable">
      <TableContainer component={Paper}>
            <Table>
            <TableHead>
                <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Last Run</TableCell>
                <TableCell>Total Runs</TableCell>
                <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.lastRun}</TableCell>
                    <TableCell>{row.totalRuns}</TableCell>
                    <TableCell>{row.actions}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default MyWorkflows;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Grid, Divider, InputAdornment, IconButton } from '@mui/material';
// import GoogleIcon from '@mui/icons-material/Google';
// import GoogleIcon from '@mui/icons-material/Google'; // Google icon from MUI
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import microsoftLogo from '../assetts/images/Microsoft_icon.svg';
// import GoogleIcon from '../assetts/images/GoogleIcon.svg';
// import googleIcon from '../assetts/images/google-icon.svg'; // Import as a regular image
import { LinkedIn } from 'react-linkedin-login-oauth2';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from '../assetts/images/RUNMYBOT_logo.svg'
import sideImage from '../assetts/images/side-image-login.svg'; // Replace with your side image path
import { Lock, Person } from '@mui/icons-material';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import cornerImage from '../assetts/images/Corner_image.svg'; // Adjust the path as necessary

import LinkedInIcon from '../assetts/images/linkedin.svg';
import GoogleIcon from '../assetts/images/google.svg';
import microsoftLogo from '../assetts/images/microsoft-icon.svg';
import './Login.css'

const Login = () => {
    const [email, setEmail] = useState('');
    const [placeholderText, setPlaceholderText] = useState("Username");
    const [placeholderTextPassword, setPlaceholderTextPassword] = useState("Password");
    const [password, setPassword] = useState('');
    const [usernameFocused, setUsernameFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [inputValuePassword, setInputValuePassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = () => {
      setShowPassword(true);
    };
    const handleMouseUpPassword = () => {
      setShowPassword(false);
    };
    const handleInputPasswordChange = (event) => {
      const newValue = event.target.value;
      setInputValuePassword(newValue);
      
      // Check if input value is empty and update placeholder if needed
      if (event.target.value.trim() === "") {
        setPlaceholderTextPassword("Password");
      } else {
        setPlaceholderTextPassword("");
      }
    };
    const handleInputChange = (event) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      
      // Check if input value is empty and update placeholder if needed
      if (event.target.value.trim() === "") {
        setPlaceholderText("Username");
      } else {
        setPlaceholderText("");
      }
    };
    const handleOnFocusPassword = (event) =>{
      setPlaceholderTextPassword ("");
    }
    const handleInputBlurPassword = () => {
      // Perform some action when the input loses focus
      if (inputValue.trim() === "") {
        setPlaceholderTextPassword("Password");
      } else {
        setPlaceholderTextPassword("");
      }
    };
    const navigate = useNavigate();
    const handleOnFocus =(event)=>{
      setPlaceholderText("");
    }
    const handleInputBlur = () => {
      // Perform some action when the input loses focus
      if (inputValue.trim() === "") {
        setPlaceholderText("Username");
      } else {
        setPlaceholderText("");
      }
    };
    const handleLogin = () => {
      // Implement your login logic here
      navigate('/dashboard');
    };
  
    const handleGoogleLoginSuccess = (response) => {
      console.log('Google login success:', response);
      // Implement your Google login success logic here
      navigate('/dashboard');
    };
  
    const handleGoogleLoginFailure = (response) => {
      console.error('Google login failed:', response);
    };
  
    const handleLinkedInSuccess = (response) => {
      console.log('LinkedIn login success:', response);
      // Implement your LinkedIn login success logic here
      navigate('/dashboard');
    };
  
    const handleLinkedInFailure = (response) => {
      console.error('LinkedIn login failed:', response);
    };
    // const GoogleIcon = () => (
    //   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48">
    //     <googleIcon/>
    //   </svg>
    // );
    return (
      <Grid container style={{ height: '100vh', backgroundColor:'#FFFFFF'}} spacing={0}>
        {/* Login Form */}
        <Grid item xs={12} md={6} container direction="column" justifyContent="center" alignItems="center" spacing={0} padding={10} >
        <Box sx={{ maxWidth:500, textAlign: 'center', marginLeft: '70px' }}> {/* Adjusted marginLeft */}
          {/* Image on Top Left */}
          <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
            <img src={cornerImage} alt="Login" style={{ width: '100px', height: 'auto' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', top: 0 }}>
            <img src={logo} alt="Logo" style={{ width: '279.57px', marginBottom: '20px' }} />
          </Box>
          <TextField
            label={placeholderText === "" ? "Username" : ""}
            placeholder={placeholderText}
            variant="outlined"
            fullWidth
            required
            margin="normal"
            className='customTextField'
            onFocus={handleOnFocus}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person2OutlinedIcon />
                </InputAdornment>
              )
            }}
          />
          <TextField
            label={placeholderTextPassword === "" ? "Password" : ""}
            placeholder={placeholderTextPassword}
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={inputValuePassword}
            // onChange={(e) => setPassword(e.target.value)}
            onFocus={handleOnFocusPassword}
            onChange={handleInputPasswordChange}
            onBlur={handleInputBlurPassword}
            className='customTextField'
            InputLabelProps={{
              shrink: true, // Ensures label is visible when field is focused or has a value
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseUp={handleMouseUpPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{
                      color: 'rgba(0, 0, 0, 0.3)', // Adjust the alpha value here
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button variant="contained" className='loginbutton' fullWidth onClick={handleLogin} sx={{ mt: 2, boxShadow: 'none' }}>
            Login
          </Button>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb:6 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" className='text-style'>
                Don't have an account? &nbsp;
              </Typography>
              <Typography variant="body2" className="text-style">
              <a href="/signup" style={{ textDecoration: 'none', color: 'inherit', color: '#E27DDD'  }}>
                Sign up
              </a>
            </Typography>
              {/* <Button variant="text" sx={{ color: '#E27DDD', ml: 1 }} onClick={() => navigate('/signup')}>
                Sign up
              </Button> */}
            </Box>
            <Typography variant="body2" className='text-style'>
              Forgot Password?
            </Typography>
          </Box>
          {/* <Divider sx={{ my: 2, mb:6, fontSize: '14px' }}>or <b>Login</b> with Others</Divider> */}
          <Divider
            sx={{
              my: 1,
              mb: 6,
              fontSize: '14px',
              '&::before, &::after': {
                borderColor: '#DFF6F9', // Set color of the line
              },
              '& .MuiDivider-middle': {
                backgroundColor: '#DFF6F9', // Set color of the line background
                height: '1px',
              },
            }}
          >
            or <b>Login</b> with Others
          </Divider>
          {/* Social Media Logins */}
          

          {/* <Button variant="outlined" fullWidth>
            <GoogleIcon />
            Login with Google
          </Button> */}
          <Button
            variant="outlined"
            fullWidth
            sx={{ 
              mb: 2, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              color: 'black',
              borderColor: '#1C1C1C',
              borderWidth: '1px',
              borderStyle: 'solid',
              '&:hover': {
                borderColor: '#1C1C1C',
                bgcolor: 'rgba(0, 0, 0, 0.1)',
              }
            }}
            // onClick={handleGoogleLogin}
          >
            <Box
              component="img"
              src={GoogleIcon}
              alt="Google Logo"
              sx={{ 
                height: 20, 
                mr: 1, // Margin to the right of the logo
                position: 'absolute', // Position the logo
                left: 'calc(50% - 100px)' // Align logo to the left edge of the button
              }}
            />
            Login with Google
          </Button>
          <Divider sx={{  height: '3px'  }}></Divider>
          <Button
              variant="outlined"
              fullWidth
              sx={{ 
                mb: 2, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                color: 'black',
                borderColor: '#1C1C1C',
                borderWidth: '1px',
                borderStyle: 'solid',
                '&:hover': {
                  borderColor: '#1C1C1C',
                  bgcolor: 'rgba(0, 0, 0, 0.1)',
                }
              }}
              // onClick={handleLinkedInLogin}
            >
              {/* <Box
                component="span"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  mr: 1
                }}
              >
                <LinkedInIcon />
              </Box> */}
              <Box
              component="img"
              src={LinkedInIcon}
              alt="Linkedin Logo"
              sx={{ 
                height: 20, 
                mr: 1, // Margin to the right of the logo
                position: 'absolute', // Position the logo
                left: 'calc(50% - 100px)' // Align logo to the left edge of the button
              }}
            />
              Login with LinkedIn
            </Button>
          <Button
            variant="outlined"
            fullWidth
            sx={{ 
              mb: 2, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              position: 'relative', // Needed for alignment adjustments
              fontFamily: 'Inter', // Set font to Inter
              textAlign: 'center', // Center text within the button
              color: 'black', // Set text color to black
              borderColor: '#1C1C1C', // Set border color to #1C1C1C
              borderWidth: '1px', // Set border thickness to 1px
              borderStyle: 'solid', // Ensure border style is solid
              '&:hover': {
                borderColor: 'black', // Optional: Ensure border color remains black on hover
                bgcolor: 'rgba(0, 0, 0, 0.1)', // Optional: Change background color on hover
              }
            }}
            onClick={() => {
              console.log('Microsoft login clicked');
              navigate('/dashboard');
            }}
          >
            {/* <Box
              component="img"
              src={microsoftLogo}
              alt="Microsoft Logo"
              sx={{ 
                height: 20, 
                mr: 1, // Margin to the right of the logo
                position: 'absolute', // Position the logo
                left: '16px' // Align logo to the left edge of the button
              }}
            /> */}
            <Box
              component="img"
              src={microsoftLogo}
              alt="Microsoft Logo"
              sx={{ 
                height: 20, 
                mr: 1, // Margin to the right of the logo
                position: 'absolute', // Position the logo
                left: 'calc(50% - 100px)' // Align logo to the left edge of the button
              }}
              />
              Login with Microsoft
          </Button>
        </Box>
      </Grid>

  
        {/* Side Image */}
        <Grid
          item
          xs={12}
          md={6}
          
          container
          justifyContent="center"  // Center horizontally
          alignItems="center"      // Center vertically
          style={{ backgroundColor: '#ffffff', height: '100vh'}}
        >
          <img
            src={sideImage}
            alt="Side"
            style={{
              width: '90%',       // Take full width of the container
              height: 'auto',      // Maintain aspect ratio
              maxWidth: '90vw',   // Restrict maximum width to viewport width
              maxHeight: '90vh',  // Restrict maximum height to viewport height
              objectFit: 'contain' // Adjust to ensure the whole image fits within bounds
            }}
          />
        </Grid>
      </Grid>
    );
  };
  
  export default Login;

import React from 'react';
import IntegrationTile from '../IntegrationTile/IntegrationTile';
import styles from './IntegrationsGrid.module.css';

import dataIcon from '../../../../assetts/images/data.svg';
import catalogIcon from '../../../../assetts/images/catalog.svg';
import cartIcon from '../../../../assetts/images/cart.svg';

const iconMap = {
  data: dataIcon,
  catalog: catalogIcon,
  cart: cartIcon,
};

const IntegrationsGrid = ({ tiles }) => {
  return (
    <div className={styles.gridContainer}>
      {tiles.map((tile) => (
        <IntegrationTile
          key={tile.id}
          icon={iconMap[tile.icon] || iconMap['data']}
          title={tile.title}
          category={tile.category}
        />
      ))}
    </div>
  );
};

export default IntegrationsGrid;

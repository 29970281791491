import React from 'react';
import styles from './IntegrationsHeader.module.css';
import backArrowRounded from '../../../../assetts/images/leftRoundedArrow.svg'; // Correct path to the SVG
import searchIcon from '../../../../assetts/images/searchIcon.svg'; // Correct path to the search icon SVG

const IntegrationsHeader = () => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.titleSection}>
                <h1 className={styles.title}>Our Integrations</h1>
                <div className={styles.breadcrumb}>Dashboard &gt; Our Integrations &gt; SAP / SAP Concur</div>
            </div>
            <div className={styles.searchSection}>
                <button className={styles.backButton}>
                    <img src={backArrowRounded} alt="Back" className={styles.backIcon} /> {/* Using SVG */}
                </button>
                <div className={styles.searchWrapper}>
                    <img src={searchIcon} alt="Search" className={styles.searchIcon} /> {/* Using SVG for search icon */}
                    <input
                        type="text"
                        className={styles.searchInput}
                        placeholder="Search..."
                    />
                </div>
            </div>
        </div>
    );
};

export default IntegrationsHeader;

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import UserManagement from './pages/UserManagement';
import Reports from './pages/Reports';
import Logs from './pages/Logs';
import JobSchedule from './pages/JobSchedule';
import MyTemplates from './pages/MyTemplates';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Signup from './pages/Signup';
import WorkflowLibraryPage from './pages/workflows/pages/WorkflowLibraryPage/WorkflowLibraryPage';
import IntegrationsLibraryPage from './pages/integrations/pages/IntegrationsLibraryPage/IntegrationsLibraryPage';
import DetailViewPage from './pages/DetailView.js/pages/DetailViewPage/DetailViewPage';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/logs" element={<Logs />} />
        <Route path="/job-schedule" element={<JobSchedule />} />
        <Route path="/my-templates" element={<MyTemplates />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/workflow-library" element={<WorkflowLibraryPage />} />
        <Route path="/integrations-library" element={<IntegrationsLibraryPage />} />
        <Route path="/detail-view" element={<DetailViewPage />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;

import React from 'react';
import styles from './AutomationFlowComponent.module.css';
import automationFlow from '../../../../assetts/images/automationFlow.png'; // Import the image

const AutomationFlowComponent = () => {
    return (
        <div className={styles.automationFlowContainer}>
            <div className={styles.header}>
                <span className={styles.dateTime}>08-12-2024 09:30 PM</span>
                <button className={styles.cancelButton}>
                    <span className={styles.cancelButtonText}>Cancel Run</span>
                </button>
            </div>
            <div className={styles.flowDiagram}>
                <img src={automationFlow} alt="Automation Flow" className={styles.automationFlowImage} />
            </div>
        </div>
    );
};

export default AutomationFlowComponent;

import React, { useState } from 'react';
import styles from './DetailViewSidebar.module.css';
import { ReactComponent as SystemSettingsIcon } from '../../../../assetts/images/systemSettings.svg'; // Icon used for both date and status
import { ReactComponent as DropdownIconClosed } from '../../../../assetts/images/dropdownIconClosed.svg'; // Closed dropdown arrow icon
import { ReactComponent as Warning } from '../../../../assetts/images/warning.svg'; // Warning icon for errors

const DetailViewSidebar = () => {
    const dummyLogs = [
        { id: 1, date: '08-12-2024', time: '09:30 PM', status: 'Warning' },
        { id: 2, date: '08-11-2024', time: '09:30 PM', status: 'All' },
        { id: 3, date: '08-10-2024', time: '09:30 PM', status: 'Error' },
        { id: 4, date: '08-09-2024', time: '09:30 PM', status: 'Warning' },
        { id: 5, date: '08-08-2024', time: '09:30 PM', status: 'All' },
        { id: 6, date: '08-07-2024', time: '09:30 PM', status: 'Warning' },
        { id: 7, date: '08-06-2024', time: '09:30 PM', status: 'Error' },
        { id: 8, date: '08-05-2024', time: '09:30 PM', status: 'All' }
    ];

    const [selectedStatus, setSelectedStatus] = useState('All');
    const [highlightedLog, setHighlightedLog] = useState(null);

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleLogClick = (log) => {
        setHighlightedLog(log.id); // Set the highlighted log by its ID
    };

    const filteredLogs = dummyLogs.filter((log) => {
        if (selectedStatus === 'All') return true;
        return log.status === selectedStatus;
    });

    return (
        <div className={styles.sidebar}>
            <div className={styles.filterSection}>
                {/* Date Filter */}
                <div className={styles.filterItem}>
                    <div className={styles.filterHeader}>
                        <SystemSettingsIcon className={styles.filterIcon} />
                        <span className={styles.filterTitle}>Date*</span>
                    </div>
                    <input type="text" value="August-01-2024 12:30 AM" readOnly className={styles.dateInput} />
                    <span className={styles.dateSeparator}>to</span>
                    <input type="text" value="August-13-2024 11:59 PM" readOnly className={styles.dateInput} />
                </div>

                {/* Status Filter */}
                <div className={styles.filterItem}>
                    <div className={styles.filterHeader}>
                        <SystemSettingsIcon className={styles.filterIcon} />
                        <span className={styles.filterTitle}>Status*</span>
                    </div>
                    <div className={styles.statusDropdown}>
                        <select className={styles.dropdown} onChange={handleStatusChange} value={selectedStatus}>
                            <option value="All">All</option>
                            <option value="Warning">Warning</option>
                            <option value="Error">Error</option>
                        </select>
                        <DropdownIconClosed className={styles.dropdownIcon} />
                    </div>
                </div>
            </div>

            <div className={styles.listSection}>
                <ul className={styles.logList}>
                    {filteredLogs.map((log) => (
                        <li
                            key={log.id}
                            className={`${styles.logItem} ${highlightedLog === log.id ? styles.highlighted : ''}`}
                            onClick={() => handleLogClick(log)}
                        >
                            <span className={styles.logDate}>{log.date}</span>
                            <span className={styles.logTime}>{log.time}</span>
                            {log.status === 'Warning' ? (
                                <Warning className={styles.statusIcon} />
                            ) : (
                                <p>*</p> 
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DetailViewSidebar;

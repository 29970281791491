import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Box, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PeopleIcon from '@mui/icons-material/People';
import ReportIcon from '@mui/icons-material/AssessmentOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ViewCompactAltOutlinedIcon from '@mui/icons-material/ViewCompactAltOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import profileImage from '../assetts/images/profile-image.svg';
import MenuIcon from '@mui/icons-material/Menu'; // Menu button for mobile

const menuItems = [
  { text: 'Dashboard', path: '/dashboard', icon: <DashboardOutlinedIcon /> },
  { text: 'User Management', path: '/user-management', icon: <PeopleIcon /> },
  { text: 'Reports', path: '/reports', icon: <ReportIcon /> },
  { text: 'Logs', path: '/logs', icon: <BusinessCenterOutlinedIcon /> },
  { text: 'Job Schedule', path: '/job-schedule', icon: <CalendarMonthOutlinedIcon /> },
  { text: 'My Templates', path: '/my-templates', icon: <ViewCompactAltOutlinedIcon /> },
  { text: 'Profile', path: '/profile', icon: <Person2OutlinedIcon /> },
  { text: 'Settings', path: '/settings', icon: <SettingsOutlinedIcon /> },
];

const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ overflow: 'hidden', width: '100%', mt:3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2, marginTop: 10 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Avatar sx={{ mr: 2 }} src={profileImage} alt="Profile Image" />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ fontSize: '18px', letterSpacing: '0.01em', lineHeight: '21.78px', fontWeight: '700' }}>
              User Name
            </Typography>
            <Typography variant="body2" sx={{ color: 'grey', fontSize: '13px' }}>
              HR Manager
            </Typography>
          </Box>
        </Box>

        <IconButton edge="end" aria-label="more" aria-controls="profile-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <MoreHorizIcon />
        </IconButton>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
          <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
        </Menu>
      </Box>

      <List>
        {menuItems.map((item, index) => (
          <ListItem button key={index} onClick={() => navigate(item.path)}>
            <ListItemIcon sx={{ minWidth: '40px', marginLeft: '5px' }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} sx={{ flex: '1 1 auto', marginLeft: '0px' }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {/* Mobile menu button */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}
        onClick={handleDrawerToggle}
      >
        <MenuIcon />
      </IconButton>

      {/* Sidebar */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          width: 263,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 263,
            boxSizing: 'border-box',
            overflowX: 'hidden',
            backgroundColor: '#F5F9FF',
            border: 'none',
            '@media (max-width:600px)': {
              position: 'fixed',
              zIndex: 1200,
              width: '100%',
              height: '100%',
            },
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Sidebar;

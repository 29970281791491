import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import AppRoutes from './Routes';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

function App() {
  
  return (
    <ThemeProvider  theme={theme}>
      <Box sx={{ backgroundColor: '#F5F9FF', minHeight: '70vh',alignItems:'center' }}>
        <AppRoutes />
      </Box>
    </ThemeProvider>
  );
}

export default App;
import React from 'react';
import StorageIcon from '@mui/icons-material/Storage'; 
import styles from './WorkflowTile.module.css';
import infoPink from '../../../../assetts/images/infoPink.svg';
import settingsBlue from '../../../../assetts/images/settingsBlue.svg';

const WorkflowTile = ({ icon, title, category }) => {
    return (
      <div className={styles.tile}>
        <div className={styles.tileContent}>
          <div className={styles.iconContainer}>
            <img src={icon} alt="Icon" className={styles.tileIcon} /> {/* Render SVG dynamically */}
          </div>
          <div className={styles.title} title={title}>{title}</div>
        </div>
        <div className={styles.footer}>
          <span className={styles.category}>{category}</span>
          <div className={styles.actions}>
            <img src={infoPink} alt="Info" className={styles.infoIcon} />
            <img src={settingsBlue} alt="Settings" className={styles.actionIcon} />
          </div>
        </div>
      </div>
    );
  };
  
  export default WorkflowTile;

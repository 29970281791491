import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Grid } from '@mui/material';
import logo from '../assetts/images/RUNMYBOT_logo.svg'
import sideImage from '../assetts/images/side-image-signup.svg'; // Replace with your side image path
import cornerImage from '../assetts/images/Corner_image2.svg'; 
import './Login.css'

const Signup = () => {
  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();
  const handleSignup = () => {
    // Implement your signup logic here
    navigate('/dashboard');
  };
  const [focusedField, setFocusedField] = useState({});

  const handleFocus = (field) => {
    setFocusedField((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field, value) => {
    if (!value) {
      setFocusedField((prev) => ({ ...prev, [field]: false }));
    }
  };

  const handleChange = (field) => (e) => {
    setFormFields((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const [showFirstNameLabel, setShowFirstNameLabel] = useState(false);
  const [showLastNameLabel, setShowLastNameLabel] = useState(false);
  const [showEmailLabel, setShowEmailLabel] = useState(false);
  const [showPhoneNumberLabel, setShowPhoneNumberLabel] = useState(false);
  const [showPasswordLabel, setShowPasswordLabel] = useState(false);
  const [showConfirmPasswordLabel, setShowConfirmPasswordLabel] = useState(false);
  return (
    <Grid container style={{ height: '100vh' , backgroundColor:'#FFFFFF'  }} spacing={0}>
      {/* Signup Form */}
      <Grid item xs={12} md={6} container direction="column" justifyContent="center" alignItems="center"  spacing={0} padding={10}>
      <Box sx={{ textAlign: 'center', marginLeft: '70px' , maxWidth:470 }}>
      {/* Image on Top Left */}
      <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
        <img src={cornerImage} alt="Login" style={{ width: '100px', height: 'auto' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', top: 0 }}>
        <img src={logo} alt="Logo" style={{ width: '279.57px', marginBottom: '20px' }} />
      </Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontFamily: 'Inter, sans-serif', // Make sure to import the Inter font in your project
          fontSize: '40px',
          fontWeight: 600,
          lineHeight: '48.41px',
          textAlign: 'left',
          color:'#7F7F7F',
          mb:6
        }}
      >
        Create an account
      </Typography>

      {/* First Name and Last Name Inputs */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ marginBottom: '-10px', textAlign: 'left' }}>
            First Name<span style={{ color: '#E27DDD' }}>*</span>
          </Typography>
          <TextField
            className='customTextFieldSignup'
            placeholder={!focusedField.firstName ? 'First Name' : ''}
            
            variant="outlined"
            fullWidth
            margin="normal"
            value={formFields.firstName}
            onChange={handleChange('firstName')}
            required
            onFocus={() => handleFocus('firstName')}
            onBlur={() => handleBlur('firstName', formFields.firstName)}
            
          />
      </Grid>

      {/* Last Name Input */}
      <Grid item xs={12} sm={6}>
      <Typography sx={{ marginBottom: '-10px', textAlign: 'left' }}>
        Last Name<span style={{ color: '#E27DDD' }}>*</span>
      </Typography>
      <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.lastName ? 'Last Name' : ''}
          
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.lastName}
          onChange={handleChange('lastName')}
          required
          onFocus={() => handleFocus('lastName')}
          onBlur={() => handleBlur('lastName', formFields.lastName)}
          
        />
      </Grid>

      {/* Email Address Input */}
      <Typography sx={{ marginBottom: '-10px', textAlign: 'left', marginLeft: '16px'  }}>
      Email Address <span style={{ color: '#A3A2A5' }}>(Use Business ID)</span><span style={{ color: '#E27DDD' }}>*</span>
      </Typography>
      <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.email ? 'name@gmail.com' : ''}
          
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.email}
          onChange={handleChange('email')}
          required
          onFocus={() => handleFocus('email')}
          onBlur={() => handleBlur('email', formFields.email)}
          sx={{ marginLeft: '16px' , marginTop:'20px'   }} // Add left margin here
        />
        <Typography sx={{ marginBottom: '-10px', textAlign: 'left', marginLeft: '16px'  }}>
          Phone Number
        </Typography>
        <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.phoneNumber ? '+91 0000 00 00' : ''}
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.phoneNumber}
          onChange={handleChange('phoneNumber')}
          required
          onFocus={() => handleFocus('phoneNumber')}
          onBlur={() => handleBlur('phoneNumber', formFields.phoneNumber)}
          sx={{ marginLeft: '16px' , marginTop:'20px'   }} // Add left margin 
          
        />
      

      {/* Password Input */}
      <Typography sx={{ marginBottom: '-10px', textAlign: 'left', marginLeft: '16px'  }}>
        Password<span style={{ color: '#E27DDD' }}>*</span>
      </Typography>
      <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.password ? '8+ Characters required' : ''}
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.password}
          onChange={handleChange('password')}
          required
          onFocus={() => handleFocus('password')}
          onBlur={() => handleBlur('password', formFields.password)}
          sx={{ marginLeft: '16px' , marginTop:'20px'  }} // Add left margin 
          
        />

      <Typography sx={{ marginBottom: '-10px', textAlign: 'left', marginLeft: '16px'  }}>
        Confirm Password<span style={{ color: '#E27DDD' }}>*</span>
      </Typography>
      <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.confirmPassword ? '8+ Characters required' : ''}
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.confirmPassword}
          onChange={handleChange('confirmPassword')}
          required
          onFocus={() => handleFocus('confirmPassword')}
          onBlur={() => handleBlur('confirmPassword', formFields.confirmPassword)}
          sx={{ marginLeft: '16px', marginTop:'20px' }} // Add left margin 
        />
      </Grid>

      <Button variant="contained" className='loginbutton' fullWidth onClick={handleSignup} sx={{ mt: 2, boxShadow: 'none' }}>
        Sign Up
      </Button>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 3, mb:2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" className='text-style'>
              Already have an account?
              </Typography>
              <Typography variant="body2" className="text-style">
              <a href="/" style={{ textDecoration: 'none', color: 'inherit', color: '#E27DDD'  }}>
                &nbsp; Login
              </a>
            </Typography>
            </Box>
            
          </Box>
      {/* <Button
        variant="text"
        fullWidth
        onClick={() => navigate('/')}
        sx={{ mt: 2 , alignItems:'left'}}
      >
        Already have an account? Log in
      </Button> */}
    </Box>
  </Grid>


      {/* Side Image */}
      <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="left"
          alignItems="center"
          style={{ backgroundColor: '#ffffff' }}
        >
          <img
            src={sideImage}
            alt="Side"
            style={{
              width: '90%',       // Take full width of the container
              height: 'auto',      // Maintain aspect ratio
              maxWidth: '90vw',   // Restrict maximum width to viewport width
              maxHeight: '90vh',  // Restrict maximum height to viewport height
              objectFit: 'contain' // Adjust to ensure the whole image fits within bounds
            }}
          />
        </Grid>
    </Grid>
  );
};

export default Signup;

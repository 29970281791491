import React, { useState }  from 'react';
import { Drawer, List, ListItem, ListItemText, CssBaseline, AppBar, Toolbar, Box, Typography, ListItemIcon, Avatar, IconButton, MenuItem, Menu, Badge, Grid, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assetts/images/RUNMYBOT_logo2.svg'
// import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PeopleIcon from '@mui/icons-material/People';
// import ReportIcon from '@mui/icons-material/Report';
import ReportIcon from '@mui/icons-material/AssessmentOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
// import TemplateIcon from '@mui/icons-material/Template';
import ViewCompactAltOutlinedIcon from '@mui/icons-material/ViewCompactAltOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
// import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import NotificationsIcon from '@mui/icons-material/Notifications'; 
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';

import StatusCard from '../components/StatusCard';
import percent_image from '../assetts/images/percent_image.svg';


import './Dashboard.css';
import Activities from '../components/Activities';
import MyWorkflows from '../components/MyWorkflows';
import Sidebar from '../components/Sidebar';

const drawerWidth = 240;

const Dashboard = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuItems = [
    { text: 'Dashboard', path: '/dashboard', icon: <DashboardOutlinedIcon /> },
    { text: 'User Management', path: '/user-management', icon: <PeopleIcon /> },
    { text: 'Reports', path: '/reports', icon: <ReportIcon /> },
    { text: 'Logs', path: '/logs', icon: <BusinessCenterOutlinedIcon /> },
    { text: 'Job Schedule', path: '/job-schedule', icon: <CalendarMonthOutlinedIcon /> },
    { text: 'My Templates', path: '/my-templates', icon: <ViewCompactAltOutlinedIcon /> },
    { text: 'Profile', path: '/profile', icon: <Person2OutlinedIcon /> },
    { text: 'Settings', path: '/settings', icon: <SettingsOutlinedIcon /> },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        // maxWidth: '1512px', // Set max width to 1512px
        margin: '0 auto', // Center the container horizontally
        overflowX: 'hidden', // Prevent horizontal scrolling
      }}
    >
      <CssBaseline />
      <AppBar  
        position="fixed" 
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1, 
          backgroundColor: '#F5F9FF', 
          height: 80, // Set the desired height here
          boxShadow: 'none', // Remove the shadow
          borderBottom: '2px solid #E8E8E8' // Add the bottom border
        }}
      >
        <Toolbar  className="toolbar">
          <img src={logo} alt="Logo" style={{ height: '60%', marginRight: 16 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <IconButton sx={{ mr: 2, color: '#B3B3B3' }}>
              <Badge badgeContent={0} color="primary">
                <CircleNotificationsOutlinedIcon />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
        {/* Notification Icon */}
        
      </AppBar>

      <Sidebar />
      
      <Box
  component="main"
  sx={{ flexGrow: 1, bgcolor: '#F5F9FF', p: 3, ml: `${drawerWidth}px` }}
>
  <Toolbar />
  {/* Status Boxes */}
  <Grid container spacing={1} sx={{ mb: 3, mt:3 }}>
    <Grid item xs={12} sm={6} md={3}>
    {console.log("percent_image: ",percent_image)}
      <StatusCard title="Success" count="100" percentage="75%" percent_image={percent_image} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <StatusCard title="Pending" count="46" percentage="45%" percent_image={percent_image} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <StatusCard title="Warning" count="19" percentage="25%" percent_image={percent_image} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <StatusCard title="Error" count="12" percentage="15%" percent_image={percent_image} />
    </Grid>
  </Grid>
  
  {/* New Box Below Status Boxes */}
  <Grid container spacing={1} sx={{ mt: 2 }}>
    <Grid item xs={12}>
      <Card className="infoCard">
        <CardContent>
          {/* This component will be shown before created integration or workflows */}
          {/* <Activities /> */}
          <MyWorkflows />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
</Box>
    </Box>
  );
};

export default Dashboard;

import React from 'react';
import styles from './DetailViewHeader.module.css';
import backArrowRounded from '../../../../assetts/images/leftRoundedArrow.svg'; // Correct path to the SVG

const DetailViewHeader = () => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.titleSection}>
                <h1 className={styles.title}>JE File Posting in NetSuite</h1>
                <div className={styles.breadcrumb}>Job Schedule &gt; Calendar &gt; Create New</div>
            </div>
            <div className={styles.actionsSection}>
                <button className={styles.backButton}>
                    <img src={backArrowRounded} alt="Back" className={styles.backIcon} />
                </button>
                <button className={styles.actionButton}>Run Now</button>
                <button className={styles.actionButton}>Deactivate Automation</button>
                <button className={styles.actionButton}>Scheduler</button>
                <button className={styles.actionButton}>Audit Log</button>
            </div>
        </div>
    );
};

export default DetailViewHeader;

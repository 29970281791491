import React from 'react';
import AutomationFlowComponent from './AutomationFlowComponent';
import RunDetailsComponent from './RunDetailsComponent';

const AutomationDetailsPage = () => {
    return (
        <div>
            <AutomationFlowComponent />
            <RunDetailsComponent />
        </div>
    );
};

export default AutomationDetailsPage;
